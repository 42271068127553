/* ###### 6.2 Footer  ###### */

.main-footer {
	background-color: $white;
	border-top: 1px solid #edecf7;
	margin-top: auto;
  }
  
  @media (min-width: 992px) {
	.main-footer {
	  display: block;
	}
  }
  
  .sticky-pin {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
  }
  
  .sticky {
	width: 100%;
  }
  
  .side-header {
	position: sticky;
	width: 100%;
	z-index: 10 !important;
  }
  
  .main-footer {
	.container {
	  font-size: 12px;
	  padding: 38px 20px 0;
	  align-items: center;
	  justify-content: space-between;
	  color: #8587a7;
	  margin: 0 auto;
	  text-align: center;
	  justify-content: center;
	  line-height: 3.7;
	}
  
	.container-fluid {
	  font-size: 12px;
	  padding: 38px 20px 0;
	  align-items: center;
	  justify-content: space-between;
	  color: #8587a7;
	  margin: 0 auto;
	  text-align: center;
	  justify-content: center;
	  line-height: 3.7;
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }
  
  @media (min-width: 992px) {
	.main-footer .container {
	  padding: 10px 0 0;
	}
  
	.responsive-logo {
	  display: none;
	}
  }
  
  .main-footer-app {
	.container, .container-fluid {
	  border-top-width: 0;
	}
  }