.collapse:not(.show) {
	display: none;
  }
  
  .collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
	.collapsing {
	  transition: none;
	}
  }
  
  .dropup, .dropright, .dropdown, .dropleft {
	position: relative;
  }
  
  .dropdown-toggle {
	white-space: nowrap;
  
	&::after {
	  display: inline-block;
	  margin-left: 0.255em;
	  vertical-align: 0.255em;
	  content: "";
	  border-top: 0.3em solid;
	  border-right: 0.3em solid transparent;
	  border-bottom: 0;
	  border-left: 0.3em solid transparent;
	}
  
	&:empty::after {
	  margin-left: 0;
	}
  }
  
  .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 2;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.875rem;
	color: #031b4e;
	text-align: left;
	list-style: none;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid rgba(138, 153, 191, 0.125);
	border-radius: 3px;
  }
  
  .dropdown-menu-left {
	right: auto;
	left: 0;
  }
  
  .dropdown-menu-right {
	right: 0;
	left: auto;
  }
  
  @media (min-width: 576px) {
	.dropdown-menu-sm-left {
	  right: auto;
	  left: 0;
	}
  
	.dropdown-menu-sm-right {
	  right: 0;
	  left: auto;
	}
  }
  
  @media (min-width: 768px) {
	.dropdown-menu-md-left {
	  right: auto;
	  left: 0;
	}
  
	.dropdown-menu-md-right {
	  right: 0;
	  left: auto;
	}
  
	.responsive-logo {
	  display: none;
	}
  }
  
  @media (min-width: 992px) {
	.dropdown-menu-lg-left {
	  right: auto;
	  left: 0;
	}
  
	.dropdown-menu-lg-right {
	  right: 0;
	  left: auto;
	}
  }
  
  @media (min-width: 1200px) {
	.dropdown-menu-xl-left {
	  right: auto;
	  left: 0;
	}
  
	.dropdown-menu-xl-right {
	  right: 0;
	  left: auto;
	}
  }
  
  .dropup {
	.dropdown-menu {
	  top: auto;
	  bottom: 100%;
	  margin-top: 0;
	  margin-bottom: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0;
		border-right: 0.3em solid transparent;
		border-bottom: 0.3em solid;
		border-left: 0.3em solid transparent;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
	}
  }
  
  .dropright {
	.dropdown-menu {
	  top: 0;
	  right: auto;
	  left: 100%;
	  margin-top: 0;
	  margin-left: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0;
		border-bottom: 0.3em solid transparent;
		border-left: 0.3em solid;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
  
	  &::after {
		vertical-align: 0;
	  }
	}
  }
  
  .dropleft {
	.dropdown-menu {
	  top: 0;
	  right: 100%;
	  left: auto;
	  margin-top: 0;
	  margin-right: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		display: none;
	  }
  
	  &::before {
		display: inline-block;
		margin-right: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0.3em solid;
		border-bottom: 0.3em solid transparent;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
  
	  &::before {
		vertical-align: 0;
	  }
	}
  }
  
  .dropdown-menu {
	&[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
	  right: auto;
	  bottom: auto;
	}
  }
  
  .dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e8e7ef;
  }
  
  .dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #1c273c;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
  
	&:hover, &:focus {
	  color: #141c2b;
	  text-decoration: none;
	  background-color: #f4f5f8;
	}
  
	&.active, &:active {
	  color: $white;
	  text-decoration: none;
	  background-color: $primary;
	}
  
	&.disabled, &:disabled {
	  color: #8587a7;
	  pointer-events: none;
	  background-color: transparent;
	}
  }
  
  .dropdown-menu.show {
	display: block;
  }
  
  .dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.76562rem;
	color: #8587a7;
	white-space: nowrap;
  }
  
  .dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #1c273c;
  }
  
  /* ###### 3.5 Dropdown ###### */
  
  .dropdown-menu {
	padding: 0;
	border-width: 1px;
	border-radius: 0;
  }
  
  .dropdown-item {
	padding: 8px 15px;
	font-size: 13px;
  }
  
  .dropdown-header {
	padding-left: 15px;
	padding-right: 15px;
  }