/* ###### 5.3 Forms   ###### */

/* Checkbox */

.ckbox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 1;
	margin-bottom: 0;
  
	span {
	  padding-left: 15px;
  
	  &:empty {
		float: left;
	  }
  
	  &:before, &:after {
		line-height: 18px;
		position: absolute;
	  }
  
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid #97a3b9;
		top: 1px;
		left: 0;
	  }
  
	  &:after {
		top: 1px;
		left: 0;
		width: 16px;
		height: 16px;
		content: '';
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		background-size: 65%;
		background-repeat: no-repeat;
		background-position: 55% 45%;
		background-color: $primary;
		line-height: 17px;
		display: none;
	  }
  
	  &:empty {
		padding-left: 0;
		width: 3px;
	  }
	}
  
	input[type='checkbox'] {
	  opacity: 0;
	  margin: 0;
  
	  &:checked + span:after {
		display: block;
	  }
  
	  &[disabled] + span {
		opacity: .75;
  
		&:before, &:after {
		  opacity: .75;
		}
	  }
	}
  }
  
  .ckbox-inline {
	display: inline-block;
  }
  
  /* Radio Box */
  
  .rdiobox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 18px;
  
	span {
	  padding-left: 13px;
  
	  &:before, &:after {
		line-height: 18px;
		position: absolute;
	  }
  
	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid #97a3b9;
		border-radius: 50px;
		top: 2px;
		left: 0;
	  }
  
	  &:after {
		content: '';
		width: 6px;
		height: 6px;
		background-color: $white;
		border-radius: 50px;
		top: 7px;
		left: 5px;
		display: none;
	  }
  
	  &:empty {
		padding-left: 0;
		width: 16px;
		display: block;
	  }
	}
  
	input[type='radio'] {
	  opacity: 0;
	  margin: 0;
  
	  &:checked + span {
		&:before {
		  border-color: transparent;
		  background-color: $primary;
		}
  
		&:after {
		  display: block;
		}
	  }
  
	  &[disabled] + span {
		opacity: .75;
  
		&:before, &:after {
		  opacity: .75;
		}
	  }
	}
  }
  
  .rdiobox-inline {
	display: inline-block;
  }
  
  .form-group-rdiobox {
	display: flex;
	align-items: center;
  
	.rdiobox {
	  margin-bottom: 0;
  
	  + .rdiobox {
		margin-left: 30px;
	  }
  
	  span {
		padding-left: 0;
	  }
	}
  }
  
  .main-toggle {
	width: 60px;
	height: 25px;
	background-color: #b4bdce;
	padding: 2px;
	position: relative;
	overflow: hidden;
  
	span {
	  position: absolute;
	  top: 3px;
	  bottom: 3px;
	  left: 3px;
	  display: block;
	  width: 20px;
	  background-color: $white;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;
  
	  &::before, &::after {
		position: absolute;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: .5px;
		text-transform: uppercase;
		color: $white;
		top: 2px;
		line-height: 1.38;
	  }
  
	  &::before {
		content: 'on';
		left: -25px;
	  }
  
	  &::after {
		content: 'off';
		right: -29px;
	  }
	}
  
	&.on {
	  background-color: $primary;
  
	  span {
		left: 37px;
	  }
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-toggle span {
	  transition: none;
	}
  }
  
  .main-toggle-secondary.on {
	background-color: $primary;
  }
  
  .main-toggle-success.on {
	background-color: $success;
  }
  
  .main-toggle-dark.on {
	background-color: #1c273c;
  }
  
  .main-form-group {
	padding: 12px 15px;
	border: 1px solid #edecf7;
  
	&.focus {
	  border-color: #b4bdce;
	  box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
	}
  
	.form-label {
	  font-size: 13px;
	  margin-bottom: 2px;
	  font-weight: 400;
	  color: #97a3b9;
	}
  
	.form-control {
	  padding: 0;
	  border-width: 0;
	  height: 25px;
	  color: #1c273c;
	  font-weight: 500;
  
	  &:focus {
		box-shadow: none;
	  }
	}
  }