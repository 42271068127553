.breadcrumb {
	background-color: #e2e8f5;
  }
  
  .breadcrumb-style1, .breadcrumb-style2, .breadcrumb-style3 {
	border-radius: 0;
	background-color: transparent;
	padding: 0;
  }
  
  .breadcrumb-style1 .breadcrumb-item, .breadcrumb-style2 .breadcrumb-item, .breadcrumb-style3 .breadcrumb-item {
	font-size: 11px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	letter-spacing: .5px;
	text-transform: uppercase;
  }
  
  .breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
	color: #1c273c;
  }
  
  .breadcrumb-style1 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style2 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style3 .breadcrumb-item a {
	&:hover, &:focus {
	  color: $primary;
	}
  }
  
  .breadcrumb-style1 .breadcrumb-item.active, .breadcrumb-style2 .breadcrumb-item.active, .breadcrumb-style3 .breadcrumb-item.active {
	color: $primary;
  }
  
  .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	color: #97a3b9;
  }
  
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before, .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	width: 16px;
	text-align: center;
	font-family: 'Ionicons';
  }
  
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
	content: '\f3d1';
  }
  
  .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
	content: '\f287';
  }
  
  @media (max-width: 575px) {
	.breadcrumb-header {
	  display: block;
	     margin-top: 90px;
	}
  }
  
  .breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0.75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #eaeaf3;
	border-radius: 3px;
  }
  
  .breadcrumb-item {
	+ .breadcrumb-item {
	  padding-left: 0.5rem;
  
	  &::before {
		display: inline-block;
		padding-right: 0.5rem;
		color: #8587a7;
		content: "/";
	  }
  
	  &:hover::before {
		text-decoration: underline;
		text-decoration: none;
	  }
	}
  
	&.active {
	  color: #8587a7;
	}
  }