/* ###### 4.9 Perfect Scrollbar ###### */

.ps {
	overflow: hidden;
  
	> .ps__rail-y {
	  width: 2px;
	  background-color: #bec9e3;
	  z-index: 10;
	  position: absolute;
	  left: auto !important;
	  right: 0;
	  opacity: 0;
	  transition: opacity 0.2s;
  
	  > .ps__thumb-y {
		position: absolute;
		border-radius: 0;
		width: 2px;
		left: 4px;
		background-color: #97a3b9;
	  }
	}
  
	&.ps--active-y {
	  &:hover > .ps__rail-y, &:focus > .ps__rail-y {
		opacity: 1;
	  }
	}
  }
  
 